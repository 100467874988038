.cookies-alert {
    background: linear-gradient(75deg, $color-blue-darker, $color-navy-darkest);
    color: $color-white;
    text-align: center;
    padding: 10px 0;
    display: none;
    font-family: $HelveticaNeue;
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 10px);
    z-index: 90;
    border-left: 10px solid $color-green;

    .cookies-button {
        display: inline-block;
        width: 160px;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.8;
        color: $color-navy-darkest;
        background-color: $color-gray-darken;
        cursor: pointer;
        margin: 5px 20px 5px 20px;
        transition: all .3s ease-out;
        outline: none;
        border-radius: 0;
        letter-spacing: 0.05rem;

        &:hover {
            opacity: .8;
        }
    }

    p {
        display: inline-block;
        font-size: 1.5rem;
        line-height: 1.8rem;
        text-align: center;
        margin: 5px;
        letter-spacing: 0.05rem;

        a {
            color: $color-white;
            text-decoration: underline;

            &:hover {
                opacity: .8;
            }
        }
    }
}