.popup-requirements {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparentize($color-white, .5);
    z-index: 100;
    width: 100%;
    height: 100%;
    display: none;

    .requirements-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .requirements-container-wrapper {
            background: $color-white;
            border: 1px solid $color-navy-darkest;
            width: 100%;
            max-width: 85%;
            max-height: 90%;      
            overflow-y: auto;      
        }

        .requirements-container-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 25px 25px 10px;
            .header-align-left {
                img {
                    width: 100%;
                    max-width: 120px;
                    @include MQ(XLneg) {
                        max-width: 100px;
                    }
                }
            }
            .header-align-right {
                img {
                    width: 100%;
                    max-width: 120px;
                    @include MQ(XLneg) {
                        max-width: 100px;
                    }
                }
            }
        }

        .requirements-container-title {
            padding: 0 5vw;
            @include MQ(Lneg) {
                padding: 25px 2.5vw 25px;
                max-width: 100%;
            }
            .text-title {
                text-align: center;
                font-size: 2.6rem;
                font-weight: $fw-semibold;
                color: $color-navy-darkest;
                @include MQ(XLneg) {
                    font-size: 2rem;
                }
                @include MQ(Lneg) {
                    font-size: 1.8rem;
                }
            }
        }

        .requirements-container-body {
            padding: 35px 5vw 0;
            max-width: 80%;
            margin: 0 auto;
            @include MQ(Lneg) {
                padding: 0 2.5vw;
                max-width: 90%;
            }
            .text {
                font-size: 1.8rem;
                margin-bottom: 25px;
                @include MQ(Lneg) {
                    font-size: 1.6rem;
                }
            }

            .block-text-important {
                padding: 0 25px;
                @include MQ(Lneg) {
                    padding: 0;
                }
                .text {
                    color: $color-red;
                    text-align: center;
                    margin-bottom: 25px;
                    @include MQ(Lneg) {
                        text-align: left;
                    }
                }
            }
        }

        .requirements-container-info {
            padding: 0 5vw;
            max-width: 80%;
            margin: 0 auto;
            @include MQ(Lneg) {
                padding: 0 2.5vw;
                max-width: 90%;
            }
            .requirements-container-info-columns {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                @include MQ(Lneg) {
                    flex-direction: column;
                }

                .text {
                    font-size: 1.8rem;
                    @include MQ(Lneg) {
                        font-size: 1.6rem;
                    }
                    a {
                        color: $black;
                        text-decoration: none;
                        transition: .3s all ease;
                        &:hover { 
                            color: $color-red;  
                        }
                    }
                }
            }
        }

        .requirements-container-button {
            padding: 25px 5vw;
            margin: 0 auto;
            display: block;
            width: 100%;
            a {
                margin: 0 auto;
                display: block;
                padding: 15px 35px;
                text-transform: uppercase;
                color: $color-white;
                background: $color-red;
                max-width: 280px;
                text-align: center;
                font-size: 1.8rem;
                transition: .3s all ease;
                @include MQ(Lneg) {
                    font-size: 1.6rem;
                }
                &:hover {
                    background: $color-red-dark;
                }
            }
        }
    }
}

.modal-card-loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparentize($color-white, .5);
    z-index: 100;    
    display: none;    

    .modal-card-loader-block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;    
    }
    .modal-card-wrapper {
        background: $color-white;
        border: 1px solid $color-navy-darkest;
        width: 100%;
        max-width: 720px;
        height: auto;
        max-height: 100vh;
        overflow-y: auto;            

        .close-container {
            display: flex;
            justify-content: flex-end;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 54px;
                height: 54px;
                background: $color-red;
                transition: .3s all ease;
                &:hover {
                    background: $color-red-dark;
                }
                svg {
                    fill: $color-white;
                    width: 18px;
                    height: 18px;
                }
            }            
        }

        .modal-card-loader-container {
            padding: 10px 5vw;
            .modal-card-loader-title {
                .text-title {
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 2rem;
                    font-weight: $fw-bold;
                    color: $color-navy-darkest;
                }
            }

            .modal-card-loader-form {
                max-width: 440px;
                margin: 0 auto;
                padding: 25px 0;
                .modal-card-loader-subtitle {
                    .text {
                        font-size: 1.6rem;
                        text-align: center;
                        font-weight: $fw-semibold;
                        color: $color-navy-darkest;
                    }
                }

                .modal-card-loader-form-wrapper {
                    margin-top: 25px;         
                    transition: .3s all ease-in-out;     
                    
                    &.form-disabled {
                        pointer-events: none;
                        touch-action: none;      
                        opacity: .5;                                                    
                    }           

                    .form-group {
                        margin-bottom: 15px;
                        label {
                            display: block;
                            font-size: 1.6rem;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                            font-weight: $fw-semibold;
                            .field-required {
                                color: $color-blue-dark;
                                font-size: 1.6rem;
                                vertical-align: sub;
                            }
                        }
                        input, select {
                            width: 100%;
                            border: 2px solid $color-gray-66;
                            padding: 10px;
                            font-size: 1.8rem;
                            transition: .3s all ease;
                            background: $color-white;
                            &:focus {
                                border-color: $color-blue-dark;

                            }
                        }

                        .load-phone-wrapper {
                            display: flex;
                            flex-flow: row wrap;
                            .field-prefixPhone {
                                width: 120px;
                                height: 43px;
                                margin-right: 5px;
                                background: transparentize($color-blue-dark, .9);

                                outline: 0;
                                box-shadow: none;
                
                                /* needed */
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                /* SVG background image */
                                background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
                                background-size: 1rem;
                                background-position: calc(100% - 15px) center;
                                background-repeat: no-repeat;
                                text-indent: 0.01px;
                                cursor: pointer;
                
                                position: relative;
                
                                &::-ms-expand {
                                    display: none;
                                }
                            }
                            > input {
                                width: calc(100% - 125px);
                                height: 43px;
                            }
                        }

                        .parsley-errors-list {
                            width: 100%;
                            margin-top: 5px;
                            .parsley-required, li {
                                background: $color-red;
                                display: block;
                                padding: 10px 12px;
                                border-radius: 2px;
                                font-size: 1.6rem;
                                font-weight: $fw-semibold;
                                color: $color-white;
                            }
                        }

                        .personal-details-error {
                            .error-message {
                                margin-top: 2px;
                                margin-bottom: 10px;
                                width: 100%;
                                p {
                                    font-size: 1.2rem;
                                    font-weight: $fw-semibold;
                                    text-transform: uppercase;                    
                                    color: $white;
                        
                                    margin-bottom: 15px;
                        
                                    background: $color-red;
                                    border: 2px solid $color-red-dark;
                                    padding: 10px;
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }                    
                }

                .modal-card-loader-footer {
                    .card-details-message {
                        width: 100%;            
                        margin-top: 10px;
                        border: 2px solid $color-red;
                        margin-bottom: 10px;

                        .message-inner {
                            padding: 15px 20px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .message-text {
                                width: 100%;
                                padding-right: 20px;
                                display: flex;
                                align-items: center;                    
                                & > p {
                                    font-size: 1.6rem;
                                    color: $color-red;
                                    font-weight: $fw-semibold;
                                    line-height: 1.4;
                                }
                            }
                            .message-icon {
                                width: 24px;
                                height: 24px;
                                span {      
                                    display: flex;
                                    align-items: center;              
                                    justify-content: center;
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                        fill: none;
                                        stroke: $color-red;
                                        stroke-width: 2;
                                        stroke-linejoin: round;
                                        stroke-linecap: round;
                                    }
                                }
                            }
                        }
                    }
                    .modal-card-loader-button {
                        width: 100%;

                        .btn-submit {
                            display: block;
                            width: 100%;
                            background: $color-red;
                            color: $color-white;
                            transition: .3s all ease;
                            font-size: 1.6rem;
                            font-weight: $fw-bold;
                            padding: 25px;
                            text-align: center;
                            text-transform: uppercase;
                            &:hover {
                                background: $color-red-dark;
                            }
                        }
                    }
                }
            }
        }
    }
}