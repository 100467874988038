body>header>.container,
body>section>.container,
body>footer>.container {
    max-width: 1460px;
    padding: 0 20px;
    margin: 0 auto;
}

body>section {
    max-width: $XXL;
    margin: 0 auto;
}