@keyframes wiggle {
  0% {transform: rotate(10deg);}
  25% {transform: rotate(-10deg);}
  50% {transform: rotate(20deg);}
  75% {transform: rotate(-5deg);}
  100% {transform: rotate(0deg);}
}


@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
      visibility: visible;
  }
}

@keyframes slideUp {
  from {
      transform: translateY(50px);
  }
  to {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
  }
}

@keyframes slideInRight {
  from {
      transform: translateX(-325px) scale(-1, 1);
  }
  to {
      transform: translateX(0) scale(-1, 1);
      opacity: 1;
      visibility: visible;
  }
}

@keyframes slideInLeft {
  from {
      transform: translateX(325px);
  }
  to {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
  }
}
@keyframes slideInTop {
  from {
      transform: translateY(50px);
  }
  to {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
  }
}
@keyframes slideInBottom {
  from {
      transform: translateY(-50px);
  }
  to {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
  }
}

@keyframes loading {
	0%, 60%, 100% {
		transform: initial;
    background-color: #333;
	}

	30% {
		transform: translateY(-7px);
    background-color: lighten(#eee, 15%);
	}
}