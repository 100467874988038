.app-container-content[data-view=home] {
    background-image: url('../img/media/home-bg@2x.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;

    padding: 25px;
    position: relative;
    display: flex;
    flex-direction: column;

    .app-container-brand {
        padding: 50px 100px 0;
        display: flex;
        @include MQ(XLneg) {
            padding: 50px 25px 0;
        }
        @include MQ(Lneg) {
            align-items: center;
        }
        @include MQ(SMneg) {
            padding-top: 0;            
        }
        .app-container-brand-topbar {
            @include MQ(Lneg) {
                flex-grow: 1;
            }
            img {
                transition: .3s all ease-out;
                @include MQ(Lneg) {
                    width: 100%;
                    max-width: 240px;
                }
                @include MQ(Mneg) {
                    max-width: 240px;
                    padding-right: 25px;
                }
                @include MQ(SMneg) {
                    max-width: 220px;
                }
            }
        }
        .app-container-brand-float-r {
            margin-left: auto;
            padding-right: 100px;
            @include MQ(XLSneg) {
                padding-right: 0;
            }
            @include MQ(SMneg) {
                margin-left: 0;
                margin: 25px 0;
            }
            img {
                transition: .3s all ease-out;
                @include MQ(Lneg) {
                    width: 100%;
                    max-width: 160px;
                }
                @include MQ(Mneg) {
                    max-width: 140px;
                }
                @include MQ(SMneg) {
                    max-width: 120px;
                    padding-left: 25px;
                }
            }
        }
    }

    .app-container-body {
        flex: 1 0 auto;
        padding: 30px 50px;
        @include MQ(XLSneg) {
            margin-top: -100px;
        }
        @include MQ(XLneg) {
            padding: 10px 25px;            
        }
        @include MQ(Lneg) {
            margin-top: 0;
        }
        .app-body-columns {
            display: flex;
            align-items: flex-end;
            padding: 25px 100px;
            @include MQ(XLSneg) {
                padding-right: 0;
            }
            @include MQ(XLneg) {
                padding-left: 0;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
        .app-body-column-left {
            @include MQ(Mneg) {
                width: 100%;
            }
            & > p {
                color: $color-white;
                font-size: 3.6rem;
                width: 600px;
                line-height: 1.2;
                @include MQ(XLneg) {
                    width: 440px;
                    font-size: 3rem;
                }
                @include MQ(Lneg) {
                    width: auto;
                    text-align: center;
                    font-size: 2.4rem;
                }
                @include MQ(Mneg) {
                    font-size: 1.8rem;
                    line-height: 1.4;
                }
            }
            .app-button {
                margin-top: 75px;
                @include MQ(Lneg) {
                    margin-top: 50px;                    
                }
            }
        }
        .app-body-column-right {
            margin-left: auto;
            @include MQ(XLneg) {
                margin-left: 0;
            }
            @include MQ(Mneg) {
                width: 100%;
            }
            .app-button {
                margin-top: 25px;
                @include MQ(XLneg) {
                    margin-top: 0;
                }
            }
        }
    }

    .app-container-footer {
        padding: 15px 100px;
        @include MQ(XLneg) {
            padding: 15px 25px;
        }
        .app-footer-registry {
            p {
                color: $color-white;
                font-size: 1.6rem;
                font-weight: $fw-light;
                @include MQ(Mneg) {
                    font-size: 1.4rem;
                    text-align: center;
                }
            }
        }
    }
}