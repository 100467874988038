//Testing
// * {
//    outline: 1px solid black;
// }

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 62.5%;
}

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    position: relative;
    min-width: $min-width;
    font-size: 2.2rem;
    line-height: 1.4;
    font-family: $HelveticaNeue;
    text-rendering: optimizeLegibility;
    font-weight: $fw-regular;
    color: #333;
    overflow-x: hidden;

    &.disable-scroll {
        overflow: hidden;
    }
}

h1 {
    font-size: 2.8rem;
    line-height: 1.2;

    @include MQ(Mneg) {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
}

h2 {
    font-size: 2.4rem;
    font-weight: $fw-semibold;
    line-height: 1.2;

    @include MQ(Mneg) {
        font-size: 1.2rem;
        line-height: 2.2rem;
    }
}

a {
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &[href^="tel:"] {
        text-decoration: none;
    }
}

strong {
    font-weight: $fw-semibold;
}

button, .button {
    cursor: pointer;
    transition: opacity .3s ease-out;
}

input:focus {
    outline: none;
}

sup {
    font-size: 57%; 
    line-height: 0; 
    position: relative; 
    vertical-align: baseline; 
    top: -0.5em;
}