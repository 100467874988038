.app-container-form {
    .app-container-form-button {
        width: 100%;
        max-width: 440px;
        margin: 0 calc(5vw + 25px) 0 auto;
        @include MQ(XLneg) {
            margin: 0 auto;
        }
        a.btn--restore {
            color: $white;
            display: block;
            padding: 20px 35px;
            background: $color-red;
            font-size: 2.4rem;
            text-align: center;
            transition: .3s all ease;

            @include MQ(Lneg) {
                font-size: 2rem;
            }

            &:hover {
                background: $color-red-dark;
            }
        }
    }

    .app-container-form-response {
        padding: 50px 5vw 25px;
        .form-loaded {
            padding: 25px;

            .form-loaded-text {
                display: block;
                width: 100%;
                border: 4px solid $color-navy-darkest;
                h2 {
                    font-size: 2.1rem;
                    font-weight: $fw-semibold;
                    text-transform: uppercase;
                    text-align: center;
                    display: block;
                    padding: 25px;
                    color: $color-navy-darkest;
                }
            }
        }
        .form-response-wrapper {
            padding: 25px;
            .form-response-text {
                display: block;
                width: 100%;
                border: 5px solid $color-green;
                background: $color-green;
                &.response-error {
                    border: 5px solid $color-red;
                    background: $color-red;
                }
                p.form-response {
                    font-size: 2.1rem;
                    font-weight: $fw-semibold;
                    text-transform: uppercase;
                    text-align: center;
                    display: block;
                    padding: 25px;
                    color: $color-white;
                    @include MQ(Lneg) {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }

    .app-container-form-content {
        position: relative;
        .block-float-info {
            position: fixed;
            top: 35%;
            right: 50px;
            z-index: 1;
            @include MQ(Lneg) {
                top: unset;
                bottom: 35px;
                right: 25px;
            }

            .info-icon-container {
                background: $color-navy-darkest;
                border-radius: 100%;
                width: 45px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .3s all ease;
                cursor: pointer;

                .feather-info {
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    width: 35px;
                    height: 35px;
                }

                &:hover {
                    background-color: $color-blue-dark;
                    animation: wiggle .7s forwards ease-in-out;
                }
            }
        }
    }

    .form-wrapper {
        padding: 0 5vw 25px;
        
        .field-hidden {
            display: none;
            touch-action: none;
            pointer-events: none;
        }
        
        .form-field-error {
            margin-top: 5px;
            .form-error-text {
                background: $color-red;
                display: block;
                padding: 10px 12px;
                border-radius: 2px;
                p {
                    font-size: 1.6rem;
                    font-weight: $fw-semibold;
                    color: $color-white;
                }
            }
        }

        .form-group {
            padding: 0 25px;
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            flex-flow: row wrap;
            @include MQ(Lneg) {
                padding: 0;
            }

            .form-field-row {
                width: 100%;
            }
            label {
                display: block;
                margin-bottom: 10px;
                font-size: 2.2rem;
                color: $color-navy-darkest;
                @include MQ(XLneg) {
                    font-size: 2rem;
                }
                @include MQ(Lneg) {
                    font-size: 1.8rem;
                }
                sup {
                    font-size: 1.4rem;
                    vertical-align: middle;
                    font-weight: $fw-semibold;
                    color: $color-blue-dark;
                    &.field-required {
                        font-size: 1.8rem;
                    }
                    &.field-optional {
                        font-weight: $fw-regular;
                        color: $color-navy-darkest;
                    }
                }
            }
            input, select {
                width: 100%;
                border: 2px solid $color-gray-66;
                display: block;
                padding: 8px 12px;
                font-size: 1.8rem;
                transition: .3s all linear;
                @include MQ(Lneg) {
                    font-size: 1.6rem;
                }
                
                &:focus {
                    border-color: $color-blue-darker;
                    border-radius: 0;
                    box-shadow: none;
                }
            }

            select {
                outline: 0;
                box-shadow: none;
                background-color: $white;
                width: 100%;

                /* needed */
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                /* SVG background image */
                background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%2300407F%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
                background-size: 1rem;
                background-position: calc(100% - 15px) center;
                background-repeat: no-repeat;
                text-indent: 0.01px;
                cursor: pointer;

                position: relative;

                &::-ms-expand {
                    display: none;
                }
            }

            .form-field-disabled, 
            .form-field-disabled:disabled {
                background-color: $color-gray;
                pointer-events: none;
                touch-action: none;
                opacity: 1;
            }

            .field-multiple {
                display: flex;
                align-items: center;
                flex-flow: row wrap;
                select {
                    outline: 0;
                    box-shadow: none;
                    background-color: $white;

                    width: 120px;
                    margin-right: 4px;
                    height: 40px;
    
                    /* needed */
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    /* SVG background image */
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%2300407F%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
                    background-size: 1rem;
                    background-position: calc(100% - 15px) center;
                    background-repeat: no-repeat;
                    text-indent: 0.01px;
                    cursor: pointer;
    
                    position: relative;
    
                    &::-ms-expand {
                        display: none;
                    }
                }
                input {
                    width: calc(100% - 124px);
                    height: 40px;
                }
            }

            .form-field-row-legend {
                margin-top: 35px;
                p {
                    font-size: 1.6rem;
                    font-weight: $fw-semibold;
                    color: $color-navy-darkest;
                    .legend-star {
                        color: $color-blue-dark;
                        font-size: 1.6rem;
                        vertical-align: middle;
                    }
                }
            }

            .parsley-errors-list {
                width: 100%;
                margin-top: 5px;
                .parsley-required, li {
                    background: $color-red;
                    display: block;
                    padding: 10px 12px;
                    border-radius: 2px;
                    font-size: 1.6rem;
                    font-weight: $fw-semibold;
                    color: $color-white;
                }
            }
        }
    }

    .form-wrapper-clause {
        padding: 0 5vw 25px;
        @include MQ(Lneg) {
            padding: 0 5vw;
        }
        h2.clause-title {
            padding: 0 25px;
            color: $color-navy-darkest;
            font-size: 2.2rem;
            @include MQ(Lneg) {
                padding: 0;
                font-size: 1.8rem;
            }
        }

        .clause-info-wrapper {
            padding: 25px;
            @include MQ(Lneg) {
                padding: 25px 0;
            }
            .clause-description {
                padding: 15px 0;

                p {
                    font-size: 1.8rem;
                    color: $color-navy-darkest;
                    line-height: 1.4;
                    @include MQ(Lneg) {
                        font-size: 1.6rem;
                    }

                    a {
                        color: $color-navy-darkest;
                        text-decoration: none;
                        transition: .3s all ease;
                        &:hover {
                            color: $color-red;
                        }
                    }
                }
            }   

            .read-more {
                padding: 10px 0 10px;
                a.btn-read-more {
                    color: $color-red;
                    font-size: 1.6rem;
                    font-weight: $fw-semibold;
                    transition: .3s all ease;
                    &:hover {
                        color: $color-red-dark;
                    }
                }
            }

            .clause-description-hidden {
                display: none;
                &.clause-show {
                    display: block;
                }
            }

            .clause-description-show {
                display: block;                
                .form-group-checkbox {
                    margin-bottom: 25px;
                    margin-left: 35px;
                    position: relative;
                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    label {
                        cursor: pointer;
                        .label-text {
                            font-size: 1.8rem;
                            color: $color-navy-darkest;
                            line-height: 1.4;
                            @include MQ(Lneg) {
                                font-size: 1.6rem;
                            }
                            a {
                                font-weight: $fw-semibold;
                                color: $color-navy-darkest;
                                transition: .3s all ease;
                                &:hover {
                                    color: $color-red;
                                }
                            }
                        }
                    }

                    input[type=checkbox] {
                        position: absolute;
                        top: 0;
                        left: -65px;
                        pointer-events: none;
                        touch-action: none;
                        visibility: hidden;
                        opacity: 0;
                    }

                    .checkmark {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        background: $color-white;
                        border: 2px solid $color-gray-66;
                        position: absolute;
                        top: 0;
                        left: -35px;
                        z-index: 0;
                        transition: .3s all ease;
                        &::before {
                            content: '';
                            display: block;
                            width: 0;
                            height: 0;
                            background: transparent;
                            z-index: -1;
                            transition: .2s all ease;
                        }
                    }

                    input[type=checkbox]:checked + .checkmark {
                        border-color: $color-red;
                        &::before {
                            background: $color-red;
                            width: 16px;
                            height: 16px;
                        }
                    }

                    input[type=checkbox].form-field-disabled:disabled {
                        pointer-events: none;
                        touch-action: none;
                        opacity: 0;
                    }

                    input[type=checkbox].form-field-disabled:disabled + .checkmark {
                        border-color: lighten($color-red, 20%);
                        pointer-events: none;
                        touch-action: none;
                        &::before {
                            background: lighten($color-red, 20%);
                        }                    
                    }
                    

                    label.parsley-error {
                        p.label-text, 
                        p.label-text a {
                            color: $color-red;
                        }
                        .checkmark {
                            border-color: $color-red;
                        }
                    }

                    .parsley-errors-list {
                        margin-top: 5px;
                        li {
                            background: $color-red;
                            display: block;
                            padding: 10px 12px;
                            border-radius: 2px;
                            font-size: 1.6rem;
                            font-weight: $fw-semibold;
                            color: $color-white;
                        }
                    }
                }                
            }
        }
    }

    .form-group-submit {
        padding: 0 5vw 75px;
        @include MQ(Lneg) {
            padding: 0 5vw 25px;
        }
        button.btn-submit {
            background: $color-red;
            font-size: 2.4rem;
            color: $color-white;
            display: block;
            padding: 25px;
            width: 320px;
            transition: .3s all ease;
            margin: 0 25px;
            @include MQ(Lneg) {
                width: 100%;
                margin: 0;
            }
            &:disabled {
                background: lighten($color-red, 10%);
                pointer-events: none;
                touch-action: none;
            }
            &:hover {
                background: $color-red-dark;
            }
        }
    }

}