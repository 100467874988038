/* FUNCTIONS */

// Fluid Text Size
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

// Responsive Width/Height
@function vw($value){
    @return ($value / 1680) * 100 * 1vw;
}

// Columns
@function col($num) {
    @return 100% / $grid-columns * $num;
    // Usage e.g width: col(4) = (100% / 12 * 4)
}

// Line Height
@function line-height($size) {
    @return $size + 120%;
    // example: line-height: line-height(2.4);
    // Returns 2.4rem * 120%;
}

/* LOOPS */
$base-font-size: 1.8rem;
$heading-scale: 8;

@for $i from 1 through 6 {
    h#{$i} {
        margin: 0;
        padding: 0;
    }
}