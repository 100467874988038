%chevron-line-base {
    stroke-width: 10;
    stroke-linecap: square;
    -webkit-transition: -webkit-transform 0.4s, stroke 0.4s;
    transition: transform 0.4s, stroke 0.4s;
}

.app-header[data-view=faq] {
    width: 100vw;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .app-header-inner {
        img {
            width: 100%;
        }
    }
}

.app-container-content[data-view=faq] {
    background-image: url('../img/media/pattern.svg');
    background-size: cover;
    background-position: center;
    background-repeat: repeat-y;
    min-width: 100vw;
    min-height: 100vh;
    position: relative;

    .app-body {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 250px 125px 75px;
        @include MQ(XLSneg) {
            padding: 200px 84px 75px;
        }
        @include MQ(XLneg) {
            padding: 200px 50px 75px;
        }
        @include MQ(Lneg) {
            padding: 100px 50px 50px;
            flex-direction: column;
        }
        p {
            color: $color-navy-darkest;
        }
    }

    .app-container-sidebar {
        width: 540px;
        padding-right: 50px;
        @include MQ(XLneg) {
            width: 100%;
            max-width: 440px;
            padding-right: 25px;
        }
        @include MQ(Lneg) {
            width: 100%;
            max-width: unset;
        }
        .app-sidebar-brand {
            padding-bottom: 35px;       
            @include MQ(Lneg) {
                img {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                    max-width: 240px;
                }
            }     
        }

        .app-sidebar-header {
            padding-bottom: 35px;
            h1 {
                margin-bottom: 35px;
                color: $color-navy-darkest;
                font-weight: $fw-semibold;
                font-size: 4.8rem;
                @include MQ(XLneg) {
                    font-size: 4rem;
                }
                @include MQ(Lneg) {
                    font-size: 3.6rem;
                    text-align: center;
                    line-height: 1.2;
                }
            }
            h2 {
                margin-bottom: 35px;
                font-weight: $fw-regular;
                font-style: italic;
                color: $color-blue-darker;
                font-size: 2.8rem;
                @include MQ(XLneg) {
                    font-size: 2.4rem;
                }
                @include MQ(Lneg) {
                    font-size: 2.2rem;
                    text-align: center;
                    width: 60%;
                    margin: 0 auto 35px;
                    line-height: 1.2;
                }
            }
            p {
                font-weight: $fw-semibold;
                font-size: 2rem;
                @include MQ(XLneg) {
                    font-size: 1.8rem;
                }            
                @include MQ(Lneg) {
                    text-align: center;
                }
            }
        }

        .app-sidebar-additional {
            padding-bottom: 35px;
            @include MQ(Lneg) {
                text-align: center;
            }
            p {
                font-size: 2.1rem;
                @include MQ(XLneg) {
                    font-size: 1.8rem;
                }
                @include MQ(Lneg) {
                    padding: 0 50px;
                    br {
                        display: none;
                    }
                }
                strong {
                    display: block;
                    @include MQ(Lneg) {
                        display: inline-block;
                    }
                }
                a {
                    color: $color-navy-darkest;
                    font-weight: $fw-semibold;
                    transition: .2s all ease-in-out;
                    &:hover {
                        color: $color-blue;
                    }
                }
            }
        }

        .app-sidebar-infoline {
            @include MQ(Lneg) {
                text-align: center;
                padding-bottom: 25px;
            }
            p {
                font-size: 2.1rem; 
                @include MQ(XLneg) {
                    font-size: 2rem;
                }
                @include MQ(Lneg) {
                    font-size: 1.8rem;
                }
                small {
                    font-size: 1.8rem;
                    display: block;
                    @include MQ(Lneg) {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    .app-container-content {
        width: calc(100% - 580px);
        padding-right: 50px;
        @include MQ(XLSneg) {
            padding-right: 0;
        }
        @include MQ(XLneg) {
            width: calc(100% - 470px);
        }
        @include MQ(Lneg) {
            width: 100%;
        }
        .app-content-accordion-wrapper {
            .accordion-item {
                margin-bottom: 25px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .accordion-item-body {
                display: none;
                background: $color-navy-mask;
                padding: 35px;
                p {
                    font-size: 2.1rem;
                    margin-bottom: 25px;
                    line-height: 1.4;
                    @include MQ(XLSneg) {
                        font-size: 1.8rem;
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    a {
                        color: $color-red;
                        &:hover {
                            color: $color-red-dark;
                        }
                    }
                }
                &.accordion-visible {
                    display: block;
                }
            }
        }
    }

    .accordion-toggle {
        position: relative;
        padding: 20px 35px;
        margin: 0 auto;
        background: $color-navy-darkest;
        color: $color-white;
        width: 100%;
        display: flex;
        align-items: flex-start;
        &:hover {
            .accordion-header-icon {            
                .icon-inner {
                    &::before {
                        transform: translateY(-50%) rotate(-45deg);
                    }
                    &::after {
                        transform: translateY(-50%) rotate(45deg);
                    }
                }
            }
        }
        &.accordion-toggle-active {
            .accordion-header-icon {
                .icon-inner {
                    &::before {
                        transform: translateY(-50%) rotate(-45deg);
                    }
                    &::after {
                        transform: translateY(-50%) rotate(45deg);
                    }
                }
            }
        }
        .accordion-header-text {
            width: 90%;
            line-height: 1.4;
            
            @include MQ(XLSneg) {
                font-size: 2rem;
            }
        }

        // SVG
        .accordion-header-icon {
            width: 26px;
            height: 26px;
            margin-left: auto;
            margin-right: 5px;
            align-self: center;
            
            .icon-inner {
                display: block;
                height: 100%;
                position: relative;
                &::before, &::after {
                    content: '';
                    width: 16px;
                    height: 2px;
                    background: $color-white;
                    position: absolute;
                    top: 50%;
                    transition: .2s all ease-out;
                }
                &::before {                    
                    left: 0;                    
                    transform: translateY(-50%) rotate(45deg);
                }
                &::after {                
                    right: 0;
                    transform: translateY(-50%) rotate(-45deg);
                }
            }
        }
    }

    .app-container-footer {
        padding: 15px 125px 50px;
        @include MQ(XLneg) {
            padding: 15px 25px 50px;
        }
        .app-footer-registry {
            p {
                color: $color-gray-70;
                font-size: 1.6rem;
                font-weight: $fw-light;
                line-height: 1.4;
                @include MQ(Mneg) {
                    font-size: 1.4rem;
                    text-align: center;
                }
            }
        }
    }
}