.btn {
    display: inline-block;
    font-size: 2.6rem;
    position: relative;
    transition: .3s all ease-out;
    color: $color-white;  

    @include MQ(MAXneg) {
        font-size: 2.4rem;
    }
    @include MQ(XLneg) {
        font-size: 2.2rem;
    }

    @include MQ(Mneg) {
        width: 100%;
    }

    @include MQ(Mneg) {
        font-size: 1.8rem;
    }

    &--primary {
        background: $color-red;
        &:hover {
            background: darken($color-red, 3%);
        }

        .btn-inner .btn-icon {
            background: $color-red-dark;
        }
    }
    &--secondary {
        background: $color-blue;
        &:hover {
            background: darken($color-blue, 3%);
        }

        .btn-inner .btn-icon {
            background: $color-blue-dark;
        }
    }

    .btn-inner {
        display: flex;
        align-items: stretch;
        justify-content: center;
        overflow: hidden;
        position: relative;
        @include MQ(Mneg) {
            justify-content: flex-start;
        }
        
        span {
            padding-right: 10px;
            text-align: center;
            padding: 20px 64px 20px 20px;            
        }
    
        .btn-icon {
            width: 84px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transform: skewX(-25deg);
            margin-right: -25px;
            @include MQ(Mneg) {
                margin-right: 10px;
                margin-left: auto;
            }
            svg {
                fill: none;
                stroke: $color-white;
                stroke-width: 2;
                stroke-linecap: round;
                stroke-linejoin: round;
                width: 28px;
                height: 28px;
                transform: skewX(25deg) translateX(-10px);
                @include MQ(Mneg) {
                    transform: skewX(25deg) translateX(0);
                }
            }
        }
    }    
}