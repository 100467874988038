$font-path: "../fonts/";

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Lt.eot');
  src: local('Helvetica Neue LT Pro 45 Light'), local('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Lt'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Lt.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Lt.woff') format('woff'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Lt.ttf') format('truetype'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Lt.svg#HelveticaNeueLTPro-Lt') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Roman.eot');
  src: local('Helvetica Neue LT Pro 55 Roman'), local('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Roman'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Roman.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Roman.woff') format('woff'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Roman.ttf') format('truetype'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Roman.svg#HelveticaNeueLTPro-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-It.eot');
  src: local('Helvetica Neue LT Pro 56 Italic'), local('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-It'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-It.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-It.woff2') format('woff2'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-It.woff') format('woff'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-It.ttf') format('truetype'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-It.svg#HelveticaNeueLTPro-It') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Md.eot');
  src: local('Helvetica Neue LT Pro 65 Medium'), local('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Md'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Md.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Md.woff2') format('woff2'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Md.woff') format('woff'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Md.ttf') format('truetype'),
      url('#{$font-path}/HelveticaNeue/HelveticaNeueLTPro-Md.svg#HelveticaNeueLTPro-Md') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('#{$font-path}/fontawesome/fontawesome-webfont.eot?v=3.0.1');
  src: url('#{$font-path}/fontawesome/fontawesome-webfont.eot?#iefix&v=3.0.1') format('embedded-opentype'),
    url('#{$font-path}/fontawesome/fontawesome-webfont.woff?v=3.0.1') format('woff'),
    url('#{$font-path}/fontawesome/fontawesome-webfont.ttf?v=3.0.1') format('truetype');
  font-weight: normal;
  font-style: normal;
}